@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global font imports */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Nunito:wght@400;600;700&display=swap');

/* Apply fonts */
body {
  font-family: 'Inter', sans-serif;
  font-size: 16px; /* Base font size for readability on mobile */
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

/* Apply transition to all elements */
* {
  transition: all 0.4s ease;
}

/* Specific transition for buttons */
button {
  transition: background-color 0.4s ease, transform 0.3s ease;
}

button:hover {
  transform: scale(1.02);
}

button, input, textarea, select, label, span, p {
  font-family: 'Inter', sans-serif;
}
